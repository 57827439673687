.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #e75600; /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 10px;
  animation: spin 1.5s linear infinite;
  margin-left: 45%;
  margin-top: 45%;
}
.wrapper {
  width: 100%;
  height: 350px;
  flex-wrap: wrap;
  margin: auto;
  display: block;
  position: relative;
  animation: fadeIn 1s;
  object-fit: cover;
  max-width: 500px;
  background-size: cover;
}
.businessname {
  vertical-align:middle;
  text-align: center;
  animation: fadeIn 2s;
  font-weight: bold;
  font-size: 20px;
  font-family: Avenir;

}
.businessweb {
  transition-property: background-color, font-size, transform, color;
  transition-timing-function: ease-in-out;
  transition-duration: 2s;
  height: 20px;
  background-color: #e75600;
  vertical-align:middle;
  text-align: center;
  color: white;
  font-family: Avenir;
  font-size: 14;
  text-decoration:none;
  width: 45%;
  border-radius: 20px;
  margin-left: 28%;
  animation: fadeIn 2s;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2)
  
}
.weburl{
  margin-top: -4px;
  margin-left: 2px;
  vertical-align:middle;
  height: 22px;
  width: 22px;
  transition-property: background-color, font-size, transform, color;
  transition-timing-function: ease-in-out;
  transition-duration: 2s;
  
  animation: fadeIn 2s;
}
.button{
  background-color: #e75600;
  vertical-align:middle;
  text-align: center;
  color: white;
  text-decoration:none;
  width: 40%;
  border-radius: 20px;
  margin-left: 25%;
  animation: fadeIn 2s;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2)
}
.inputs{
  vertical-align:middle;
  display: flex;
justify-content: center;
margin-top: 15px;
}
.maindiv{
  background-color: #f2f2f2;
  opacity:0.8;
    overflow-y: scroll;
    position:fixed;
    width:100%;
    height:100%;
    top:0px;
    left:0px;
    z-index:1000;
}
.textmessage{
  font-family :Avenir;
  font-size: 14;
  font-weight: bold;
}
.profilewrap {
  border-radius: 50%;
  height: 120px;
  width:120px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-top: -50px;
  position: relative;
  animation: fadeIn 2s;
  border-color: white;
  background-color:white;
  object-fit: cover;
  
}

.headerimage{
  height: 45px;
  width: 45px;
  margin-left: 5px;
  margin-top: 3px;
}
.userimage{
  margin-top: 2px;
  margin-left: 2px;
  vertical-align:middle;
  height: 20px;
  width: 20px;
  transition-property: background-color, font-size, transform, color;
  transition-timing-function: ease-in-out;
  transition-duration: 2s;
  
  animation: fadeIn 2s;
}
.header{
  border-bottom-style: inset;
  height: 50px;
}
.headertext{
  margin-top: -32px;
  margin-left: 55px;
  font-weight: bold;
  text-decoration: none;
  font-family: "DM Serif Text";
}
.storelinks {
  position: relative;
  margin-left: 35%;
  
}
.joincommunitystorelinks{
  position: relative;
  margin-left: 35%;
}
.businesssignature {
  margin-left: 10px;
  margin-top: 20px;
  white-space: pre-wrap;
  font-weight: bold;
  font-size: 14;
  
}
.translation{
 
  margin-left: 10px;
 
}
.sign{
  white-space:pre-wrap;
}
.playstore {
  margin-left: -14px;
  
}
.appstore {
  margin-left: 20px;
  width: 110px;
  height: 35px;

}

.load{
  vertical-align: middle;
  align-items: center;
  align-self: center;
  justify-content: center;
}
.content {
  width: 50%;
  margin-left: 25%;
  vertical-align: middle;
  animation: fadeIn 2s;
}
.joincommunitycontent{
  width: 50%;
  margin-left: 25%;
  vertical-align: middle;
  animation: fadeIn 3s;
  font-family: "DM Serif Text";
}
.joinbutton{
  transition-property: background-color, font-size, transform, color;
  transition-timing-function: ease-in-out;
  transition-duration: 2s;
  height: 23px;
  background-color: #e75600;
  vertical-align:middle;
  text-align: center;
  color: white;
  font-family: Avenir;
  font-size: 14;
  text-decoration:none;
  width: 44%;
  border-radius: 20px;
  margin-left: 28%;
  animation: fadeIn 2s;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2)
}.lds-ripple {
  display: inline-block;
  position: relative;
  width: 100px;
  height: 100px;
  margin-left: 40%;
  margin-top: 50%;
  vertical-align: middle;
  
  align-items: center;
  align-self: center;
  justify-content: center;
}
.lds-ripple div {
  position: absolute;
  border: 8px solid #e75600;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.6, 2, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}


@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 560px) {
  .content {
    width: 100%;
    margin-left: 0;
    
    
  }
  .joincommmunitycontent {
    width: 100%;
    margin-left: 0;
    
    
  }
  .lds-ripple{
    margin-left: 32%;
    margin-top: 50%;
  }
  .joinbutton{
    width:64%;
    margin-left:18%
  }
  .storelinks {
    margin-left: 30%;

  }
  .joincommunitystorelinks{
    margin-left: auto;
  }
  .playstore {
    margin-left: -15px;
    
  }
  .appstore {
    margin-left: 5px;
    width: 90px;
    height: 30px;
  
  }
.weburl{
  margin-top: -3px;
  margin-left: 1.1%;
  vertical-align:middle;
  height: 17px;
  width: 18px;
  transition-property: background-color, font-size, transform, color;
  transition-timing-function: ease-in-out;
  transition-duration: 2s;
  
  animation: fadeIn 2s;
}
.userimage{
  margin-top: -1px;
  margin-left: 2px;
  vertical-align:middle;
  height: 19px;
  width: 19px;
  transition-property: background-color, font-size, transform, color;
  transition-timing-function: ease-in-out;
  transition-duration: 2s;
  
  animation: fadeIn 2s;
}
  .wrapper {
    width: 100%;
    height:250px;
    flex-wrap: wrap;
    margin: auto;
    display: block;
    position: relative;
    object-fit: cover;
  }
}
